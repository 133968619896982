import React, { useState } from "react";
import axios from "axios";
import { Select } from "antd";
import Swal from "sweetalert2";
const { Option } = Select;

const PostForm = () => {
  const userToken = localStorage.getItem("userToken");
  const [image, setImage] = useState();
  const [imageformData, setFormDataImage] = useState({
    serviceImage: null,
  });
  const [formData, setFormData] = useState({
    categoryId: "",
    categoryName: "",
    servicePrice: "",
    serviceName: "",
    serviceDescription: "",
    serviceCity: [],
    serviceImage: "",
    serviceType: "",
    petType: "",
    serviceAddress: "",
    petBreed: "",
    petAge: 0,
    petGender: "",
    petImages: [],
    lifeExpacat: 0,
    petSize: "",
    serviceLocation: "",
    petColor: "",
    petCoatType: "",
    petHealthAssurance: "",
    petTemperament: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeImage = (e) => {
    setFormDataImage({
      ...imageformData,
      serviceImage: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const config = {
      method: "post",
      url: "https://ehb.easyhaibro.com/auth/v1/services",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      data: JSON.stringify(formData),
    };

    // Make the API call
    axios
      .request(config)
      .then(async (response) => {
        console.log(JSON.stringify(response.data));
        await Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Service created successfully.",
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const handleUploadImage = () => {
    const data = new FormData();
    data.append("mediaKey", imageformData.serviceImage);

    const config = {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios
      .post(`https://ehb.easyhaibro.com/auth/v1/media-upload`, data, config)
      .then(async (response) => {
        setFormData({
          ...formData,
          ["serviceImage"]: response.data.mediaKey,
        });
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Banner Uploaded successfully.",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangeCity = (value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      serviceCity: value,
    }));
  };

  return (
    <div className="right_sidebar">
      <div className="rs_in_third card border-0">
        <h2>Create Service</h2>

        <div className="ehb-row">
          <div className="ehb-20">
            <label>Category Name:</label>
            <select
              name="categoryName"
              value={formData.categoryName}
              onChange={handleChange}
            >
              <option value="">Select category</option>
              <option value="dogWalk">Dog Walk</option>
              <option value="petGrooming">Pet Grooming</option>
              <option value="petTraining">Pet Training</option>
              <option value="vetConsultation">Vet Consultation</option>
              <option value="petBoarding">Pet Boarding</option>
            </select>
          </div>

          <div className="ehb-20">
            <label>Pet Type:</label>
            <select
              name="petType"
              value={formData.petType}
              onChange={handleChange}
            >
              <option value="">Select Pet Type</option>
              <option value="dog">Dog</option>
              <option value="cat">Cat</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="ehb-20">
            <label>Service Type:</label>
            <select
              name="serviceType"
              value={formData.serviceType}
              onChange={handleChange}
            >
              <option value="">Select category</option>
              <option value="local">Booking Service</option>
              <option value="adoption">Pet Adoption</option>
            </select>
          </div>

          <div className="ehb-20">
            <label>
              Service Price:
              <input
                type="text"
                name="servicePrice"
                placeholder="INR Only"
                value={formData.servicePrice}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="ehb-20">
            <label>
              Service Name:
              <input
                type="text"
                name="serviceName"
                placeholder="Delux, Premium"
                value={formData.serviceName}
                onChange={handleChange}
              />
            </label>
          </div>

          <div className="ehb-20">
            <label>
              Service City:
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Select cities"
                value={formData.serviceCity}
                onChange={handleChangeCity}
                name="serviceCity"
              >
                <Option value="delhi">Delhi</Option>
                <Option value="mumbai">Mumbai</Option>
                <Option value="bengaluru">Bengaluru</Option>
                <Option value="hyderabad">Hyderabad</Option>
                <Option value="chennai">Chennai</Option>
                <Option value="kolkata">Kolkata</Option>
                <Option value="pune">Pune</Option>
                <Option value="ahmedabad">Ahmedabad</Option>
                <Option value="surat">Surat</Option>
                <Option value="jaipur">Jaipur</Option>
                <Option value="lucknow">Lucknow</Option>
                <Option value="chandigarh">Chandigarh</Option>
                <Option value="kanpur">Kanpur</Option>
                <Option value="varanasi">Varanasi</Option>
                <Option value="agra">Agra</Option>
                <Option value="dehradun">Dehradun</Option>
                <Option value="amritsar">Amritsar</Option>
                <Option value="ghaziabad">Ghaziabad</Option>
                <Option value="faridabad">Faridabad</Option>
                <Option value="noida">Noida</Option>
                <Option value="patiala">Patiala</Option>
                <Option value="jodhpur">Jodhpur</Option>
                <Option value="coimbatore">Coimbatore</Option>
                <Option value="visakhapatnam">Visakhapatnam</Option>
                <Option value="vijayawada">Vijayawada</Option>
                <Option value="mysore">Mysore</Option>
                <Option value="madurai">Madurai</Option>
                <Option value="mangalore">Mangalore</Option>
                <Option value="trichy">Trichy (Tiruchirappalli)</Option>
                <Option value="warangal">Warangal</Option>
                <Option value="hubli-dharwad">Hubli-Dharwad</Option>
                <Option value="salem">Salem</Option>
                <Option value="vadodara">Vadodara</Option>
                <Option value="nagpur">Nagpur</Option>
                <Option value="nashik">Nashik</Option>
                <Option value="aurangabad">Aurangabad</Option>
                <Option value="rajkot">Rajkot</Option>
                <Option value="bhopal">Bhopal</Option>
                <Option value="indore">Indore</Option>
                <Option value="udaipur">Udaipur</Option>
                <Option value="gandhinagar">Gandhinagar</Option>
                <Option value="bhubaneswar">Bhubaneswar</Option>
                <Option value="ranchi">Ranchi</Option>
                <Option value="patna">Patna</Option>
                <Option value="jamshedpur">Jamshedpur</Option>
                <Option value="siliguri">Siliguri</Option>
                <Option value="dhanbad">Dhanbad</Option>
                <Option value="guwahati">Guwahati</Option>
                <Option value="shillong">Shillong</Option>
                <Option value="ambala">Ambala</Option>
                <Option value="bhiwani">Bhiwani</Option>
                <Option value="charkhi_dadri">Charkhi Dadri</Option>
                <Option value="fatehabad">Fatehabad</Option>
                <Option value="hisar">Hisar</Option>
                <Option value="jhajjar">Jhajjar</Option>
                <Option value="jind">Jind</Option>
                <Option value="kaithal">Kaithal</Option>
                <Option value="karnal">Karnal</Option>
                <Option value="kurukshetra">Kurukshetra</Option>
                <Option value="mahendragarh">Mahendragarh</Option>
                <Option value="nuh">Nuh</Option>
                <Option value="palwal">Palwal</Option>
                <Option value="panchkula">Panchkula</Option>
                <Option value="panipat">Panipat</Option>
                <Option value="rewari">Rewari</Option>
                <Option value="rohtak">Rohtak</Option>
                <Option value="sirsa">Sirsa</Option>
                <Option value="sonipat">Sonipat</Option>
                <Option value="yamunanagar">Yamunanagar</Option>
                <Option value="barnala">Barnala</Option>
                <Option value="bathinda">Bathinda</Option>
                <Option value="faridkot">Faridkot</Option>
                <Option value="fatehgarh_sahib">Fatehgarh Sahib</Option>
                <Option value="firozpur">Firozpur</Option>
                <Option value="gurdaspur">Gurdaspur</Option>
                <Option value="hoshiarpur">Hoshiarpur</Option>
                <Option value="jalandhar">Jalandhar</Option>
                <Option value="kapurthala">Kapurthala</Option>
                <Option value="ludhiana">Ludhiana</Option>
                <Option value="mansa">Mansa</Option>
                <Option value="moga">Moga</Option>
                <Option value="muktsar">Muktsar</Option>
                <Option value="rupnagar">Rupnagar</Option>
                <Option value="sangrur">Sangrur</Option>
                <Option value="sri-muktsar-sahib">Sri Muktsar Sahib</Option>
                <Option value="tarn-taran">Tarn Taran</Option>
                <Option value="mohali">Mohali</Option>
                <Option value="pathankot">Pathankot</Option>
              </Select>
            </label>
          </div>

          <div className="ehb-20">
            <label>
              Pet Color:
              <select
                name="petColor"
                value={formData.petColor}
                onChange={handleChange}
              >
                <option value="">Select Pet Color</option>
                <option value="white">White</option>
                <option value="brown">Brown</option>
                <option value="black">Black</option>
                <option value="golden">Golden</option>
              </select>
            </label>
          </div>

          <div className="ehb-20">
            <label>
              Pet CoatType:
              <select
                name="petCoatType"
                value={formData.petCoatType}
                onChange={handleChange}
              >
                <option value="">Select Pet Coat Type</option>
                <option value="short">Short</option>
                <option value="medium">Medium</option>
                <option value="long">Long</option>
                <option value="hypoallergenic">Hypoallergenic</option>
                <option value="mumbai">Mumbai</option>
              </select>
            </label>
          </div>

          <div className="ehb-20">
            <label>
              Pet Health Assurance:
              <select
                name="petHealthAssurance"
                value={formData.petHealthAssurance}
                onChange={handleChange}
              >
                <option value="">Select Health Assurance</option>
                <option value="vaccinated">Vaccinated</option>
                <option value="health_certificate">Health Certificate</option>
                <option value="dewormed">Dewormed</option>
              </select>
            </label>
          </div>

          <div className="ehb-20">
            <label>
              Pet Temperament:
              <select
                name="petTemperament"
                value={formData.petTemperament}
                onChange={handleChange}
              >
                <option value="">Select Temperament</option>
                <option value="friendly">Friendly</option>
                <option value="active">Active</option>
                <option value="calm">Calm</option>
                <option value="independent">Independent</option>
              </select>
            </label>
          </div>

          <div className="ehb-20">
            <label>
              Pet Breed:
              {formData.petType === "dog" && (
                <select
                  name="petBreed"
                  value={formData.petBreed}
                  onChange={handleChange}
                >
                  <option value="">Select Pet Breed</option>
                  <option value="labrador_retriever">Labrador Retriever</option>
                  <option value="german_shepherd">German Shepherd</option>
                  <option value="golden_retriever">Golden Retriever</option>
                  <option value="beagle">Beagle</option>
                  <option value="bulldog">Bulldog</option>
                  <option value="poodle">Poodle</option>
                  <option value="dachshund">Dachshund</option>
                  <option value="boxer">Boxer</option>
                  <option value="rottweiler">Rottweiler</option>
                  <option value="chihuahua">Chihuahua</option>
                  <option value="shih_tzu">Shih Tzu</option>
                  <option value="pug">Pug</option>
                  <option value="cocker_spaniel">Cocker Spaniel</option>
                  <option value="dalmatian">Dalmatian</option>
                  <option value="siberian_husky">Husky</option>
                  <option value="doberman_pinscher">Doberman Pinscher</option>
                  <option value="pomeranian">Pomeranian</option>
                  <option value="border_collie">Border Collie</option>
                  <option value="great_dane">Great Dane</option>
                  <option value="bull_terrier">Bull Terrier</option>
                  <option value="saint_bernard">Saint Bernard</option>
                  <option value="schnauzer">Schnauzer</option>
                  <option value="mastiff">Mastiff</option>
                  <option value="akita">Akita</option>
                  <option value="shar_pei">Shar Pei</option>
                </select>
              )}
              {formData.petType === "cat" && (
                <select
                  name="petBreed"
                  value={formData.petBreed}
                  onChange={handleChange}
                >
                  <option value="">Select Pet Breed</option>
                  <option value="persian">Persian</option>
                  <option value="siamese">Siamese</option>
                  <option value="maine_coon">Maine Coon</option>
                  <option value="ragdoll">Ragdoll</option>
                  <option value="bengal">Bengal</option>
                  <option value="sphynx">Sphynx</option>
                  <option value="british_shorthair">British Shorthair</option>
                  <option value="scottish_fold">Scottish Fold</option>
                  <option value="abyssinian">Abyssinian</option>
                  <option value="birman">Birman</option>
                  <option value="exotic_shorthair">Exotic Shorthair</option>
                  <option value="turkish_van">Turkish Van</option>
                  <option value="russian_blue">Russian Blue</option>
                  <option value="egyptian_mau">Egyptian Mau</option>
                  <option value="himalayan">Himalayan</option>
                  <option value="american_shorthair">American Shorthair</option>
                  <option value="burmese">Burmese</option>
                  <option value="norwegian_forest">Norwegian Forest Cat</option>
                  <option value="savannah">Savannah</option>
                  <option value="tonkinese">Tonkinese</option>
                  <option value="manx">Manx</option>
                  <option value="cornish_rex">Cornish Rex</option>
                  <option value="devon_rex">Devon Rex</option>
                </select>
              )}
            </label>
          </div>

          <div className="ehb-20">
            <label>
              Pet Age (in weeks):
              <input
                type="number"
                name="petAge"
                placeholder="Delux, Premium"
                value={formData.petAge}
                onChange={handleChange}
              />
            </label>
          </div>

          <div className="ehb-20">
            <label>
              Pet Gender:
              <select
                name="petGender"
                value={formData.petGender}
                onChange={handleChange}
              >
                <option value="">Select Pet Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </label>
          </div>

          <div className="ehb-20">
            <label>
              Pet Size:
              <select
                name="petSize"
                value={formData.petSize}
                onChange={handleChange}
              >
                <option value="">Select Pet Size</option>
                <option value="small">Small</option>
                <option value="medium">Medium</option>
                <option value="large">Large</option>
              </select>
            </label>
          </div>

          <div className="ehb-50">
            <label>
              Service Description:
              <textarea
                name="serviceDescription"
                placeholder="<ol> 
          <li>Your values here</li>
          <li>Your values here</li>
          </ol>"
                value={formData.serviceDescription}
                onChange={handleChange}
              />
            </label>
          </div>
          <div className="ehb-50">
            <label>
              Service Image URL:
              <input type="file" name="mediaKey" onChange={handleChangeImage} />
              <button onClick={handleUploadImage}>Upload Image</button>
            </label>
          </div>
        </div>
        <button onClick={handleSubmit} type="submit">
          Create Service
        </button>
      </div>
    </div>
  );
};

export default PostForm;
